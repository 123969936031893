import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form
} from 'react-bootstrap';
import { CiTrash } from "react-icons/ci";
import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import global from "../../global";
import ModalLoad from './ModalLoad';
import ModalSucesso from './ModalSucesso';
import ModalErro from './ModalErro';

ModalAlterarNome.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    user: PropTypes.object
}

export default function ModalAlterarNome({showModal, setShowModal, user}) {

    const [novoNome, setNovoNome] = useState("");

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //Modal Sucesso 
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso, setMsgModalSucesso] = useState("");

    //Modal Erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState("");

    return(
        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) } }
        keyboard={false}
        id="modalAlterarNome"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Container>
                        <Row>
                            <Col>
                                <h4>Alterar nome do contador</h4> 
                            </Col>
                        </Row>
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalLoad
                    showModal={showModalLoad}
                />
                <ModalSucesso
                    showModal={showModalSucesso}
                    setShowModal={setShowModalSucesso}
                    msg={msgModalSucesso}
                />
                <ModalErro
                    showModal={showModalErro}
                    setShowModal={setShowModalErro}
                    msg={msgModalErro}
                />
                <Row style={{marginBottom: "5%"}}>
                    <Col style={{textAlign: "center", alignItems: "center"}}>
                        <Form.Label>Novo nome:</Form.Label>
                        <Form.Control
                            value={novoNome}
                            onChange={(e) => setNovoNome(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                            variant="success"
                            onClick={ () => {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_ALTERAR_NOME, {
                                    email: user.Email,
                                    novoNome: novoNome
                                })
                                .then((result) => {
                                    let data = result.data;

                                    if(data.code == 0){
                                        setShowModalSucesso(true);
                                        setMsgModalSucesso(data.msg);
                                        user.Nome = novoNome;
                                    }else{
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                                    }
                                })
                                .catch((error) => {

                                    Sentry.captureException(error);
                                    setMsgModalErro("Ocorreu um erro ao tentar alterar o nome do contador. Tente novamente: " + error);
                                    setShowModalErro(true);
                                    //console.log(error);
                                })
                                .finally(() => {
                                    setShowModalLoad(false);
                                });

                            }}
                            >OK</Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: "1%"}} >
                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                            variant="danger"
                            onClick={ () => {
                                setShowModal(false);
                            }}
                            >Cancelar</Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
    

}
