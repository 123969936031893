import { Container, Row, Col, FloatingLabel, Form, Button, Table } from "react-bootstrap";
import NavbarOp from "../components/Navbar";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import ModalSucesso from "../components/Modal/ModalSucesso";
import * as Sentry from "@sentry/react";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import global from "../global";

import { BsArrowLeftSquare } from "react-icons/bs";
import { BsArrowRightSquare } from "react-icons/bs";


export default function ListaNFCe(){

    const [user, setUser] = React.useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = React.useState(null);
    const [notasNfce, setNotasNfce] = React.useState([]);
    const [notasNfceTodas, setNotasNfceTodas] = useState([]);
    const [prontoParaImprimir, setProntoParaImprimir] = useState(false);
    

    //console.log(user);

    const [numNFCe] = React.useState(0);
    const [dateDe, setdateDe] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAte, setdataAte] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [pagina, setPagina] = React.useState(1);
    const [totalPaginas, setTotalPaginas] = React.useState(0);
    const [totalRegistros, setTotalRegistros] = React.useState(0);
    //padrão igual do erp
    const [totalPorPagina] = React.useState(15);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");

    //Modal Sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgModalSucesso] = React.useState("");

    //functions
    const countNFCe = useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_CONTAR_NFCE, {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                idFilial: empresaSelecionada.IdEmpresa ,
                numeroNFCe: numNFCe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte
    
            })
            .then(function (response) {
    
                //console.log(response);
    
                let data = response.data;
    
                if(data.code == 0){
    
                    //
                    //console.log("Count: ", data.content);
                    setTotalRegistros(data.content);
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
    
                }
    
            })
            .catch(function (error) {

                Sentry.captureException(error);
                
                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);
    
                //console.log(error);
    
            })
            .finally(() => {
              
                setShowModalLoad(false);

            });

        }

    }, [dataAte, dateDe, empresaSelecionada, numNFCe]);

    const carregarNFCe = useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_LISTANFCe, {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                idFilial: empresaSelecionada.IdEmpresa ,
                numeroNFCe: numNFCe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                numeroPagina: pagina,
                numeroPorPagina: totalPorPagina

            })
            .then(function (response) {

                //console.log(response);

                let data = response.data;

                if(data.code == 0){

                    //console.log("DATA LISTA NFCe" , data)
                    //console.log(JSON.parse(data.content));

                    //
                    setNotasNfce(JSON.parse(data.content) == null ? [] : JSON.parse(data.content));
                    countNFCe();

                }else{

                    setMsgModalErro(data.msg);
                    setShowModalErro(true);

                }
                
            })
            .catch(function (error) {

                Sentry.captureException(error);

                //console.log(error);

                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);

            })
            .finally(() => {
                setShowModalLoad(false);
            });

        }

        

    }, [dataAte, dateDe, empresaSelecionada, numNFCe, pagina, totalPorPagina]);

    // console.log("NOTAS NFCe", notasNfce);
    // console.log("TOTAL REGISTRO", totalRegistros);
    // console.log("PAGINA", pagina);

    useEffect(() => {
        carregarNFCe();
    }, [pagina]);

    useEffect(() => {
        setTotalPaginas( Math.ceil(totalRegistros / totalPorPagina) );
    }, [totalRegistros, totalPorPagina]);

    useEffect(() => {

        if(prontoParaImprimir){
            reactToPrintFn();
            setProntoParaImprimir(false);
        }

    }, [prontoParaImprimir])

    //ref
    var componentRef = React.useRef(null);
    const reactToPrintFn = useReactToPrint({contentRef: componentRef});

    return(

        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgModalErro} />
            <ModalLoad showModal={showModalLoad} setShowModal={setShowModalLoad} msg={msgModalSucesso} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgModalSucesso} />

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            
            {/* Div relatorio para impressao */}
            <Container 
                className='hidden' 
                style={{
                    display: 'none'
                }}
            >
                <div id="containerPdf" ref={componentRef} style={{padding: 20}}>
                    <h3 style={{textAlign: "center"}}>Relatório de NFCe</h3>
                    <hr />
                    <Table>
                        <thead>
                            <tr>
                                <th>Numero</th>
                                <th>Chave</th>
                                <th>Status</th>
                                <th>Data emissão</th>
                                <th>Total nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notasNfceTodas.map((item) => {
                                    return(
                                        <tr key={item.UuidNfce}>
                                            <td>{item.NumeroNFCe}</td>
                                            <td>{item.Chave}</td>
                                            <td>{item.StatusFinal}</td>
                                            <td>{( (new Date(item.DataEmissao).getDate()) <= 9 ? ("0" + new Date(item.DataEmissao).getDate().toString()) : (new Date(item.DataEmissao).getDate().toString()) ).toString() + "/" + ( (new Date(item.DataEmissao).getMonth() + 1) <= 9 ? ("0" + (new Date(item.DataEmissao).getMonth() + 1).toString()) : new Date(item.DataEmissao).getMonth() + 1).toString()  + "/" + new Date(item.DataEmissao).getFullYear().toString()}</td>
                                            <td>{item.valorTotalNota != null ? item.valorTotalNota.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>

                </div>
            </Container>

            <Row style={{marginTop: "1%", textAlign: "center"}}>
                <h1>NFC-e</h1>
            </Row>

            <hr/>

            <Container style={{marginTop: "1%"}}>
            
                <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    >
                        <FloatingLabel
                            label="De: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dateDe} 
                                max={dataAte}
                                onChange={(e) => {

                                    setdateDe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    >
                        <FloatingLabel
                            label="Até: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dataAte} 
                                max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                                onChange={(e) => {

                                    setdataAte(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>

                {/* <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Numero NFC-e: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="number"
                                value={numNFCe} 
                                onChange={(e) => {

                                    setnumNFCe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    
                </Row> */}
                

            </Container>

            <Row>
                <Col>
                    {totalRegistros > 0 ? <h6>Total: {totalRegistros}</h6> : null}
                </Col>
            </Row>

            <hr />

            <Row 
            style={{marginBottom: "1%"}}
            
            >
                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            carregarNFCe();

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>
                
                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {
                            if(empresaSelecionada != null){

                                setShowModalLoad(true);
                            
                                axios.post(global.URL_BASE + global.PATH_LISTANFCe, {
                                    
                                    idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                                    idFilial: empresaSelecionada.IdEmpresa ,
                                    numeroNFCe: numNFCe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte,
                                    numeroPagina: 0,
                                    numeroPorPagina: 0
                    
                                })
                                .then(function (response) {
                    
                                    //console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        //console.log("DATA LISTA NFCe" , data)
                                        //console.log(JSON.parse(data.content));
                    
                                        //
                                        setNotasNfceTodas(JSON.parse(data.content) == null ? [] : JSON.parse(data.content));
                                        setProntoParaImprimir(true);

                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                                    
                                })
                                .catch(function (error) {
                    
                                    Sentry.captureException(error);
                    
                                    //console.log(error);
                    
                                    setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                                    setShowModalErro(true);
                    
                                })
                                .finally(() => {
                                    setShowModalLoad(false);
                                });
                    
                            }
                        }}
                        >
                            Imprimir relatorio
                        </Button>
                    </div>
                </Col>
                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {
                            
                            try {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_BAIXARXMLNFCe, {
                    
                                    idOrganizacao: empresaSelecionada.IdOrganizacao ,
                                    idFilial: empresaSelecionada.IdEmpresa ,
                                    numeroNFCe: numNFCe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte
                    
                                })
                                .then(function (response) {
                    
                                    //console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        //console.log("DATA LISTA NFCe" , data)
                    
                                        // Decodificar Base64
                                        const base64Content = data.content;
                                        const binaryString = atob(base64Content);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }

                                        // Criar Blob a partir dos dados binários
                                        const blob = new Blob([bytes], { type: 'application/zip' });

                                        // Criar URL para o Blob
                                        const url = window.URL.createObjectURL(blob);

                                        //console.log("dataAte", dataAte);

                                        // Criar elemento de link e acionar o download
                                        const nomeArquivo = 'NFCE_' + empresaSelecionada.NomeFantasia
                                        .normalize('NFD') // Decompor caracteres com acentos
                                        .replace(/[\u0300-\u036f]/g, '') // Remover os diacríticos (acentos)
                                        .replace(/\s+/g, '_') + "_" +
                                        dateDe.replaceAll("-", "_") + 
                                        (   dataAte != null 
                                            ? 
                                                "_ATE_" +
                                                dataAte.replaceAll("-", "_")
                                            :
                                            new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()
                                        ) +
                                        '.zip'
                                        ; // Substituir espaços por underscores
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', nomeArquivo); // Nome do arquivo a ser baixado
                                        document.body.appendChild(link);
                                        link.click();

                                        // Remover o link do DOM
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                        
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                    
                                    setShowModalLoad(false);
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                                    setShowModalErro(true);
                                    
                                    setShowModalLoad(false);
                    
                                    //console.log(error);
                    
                                });
                                
                            } catch (error) {

                                Sentry.captureException(error);
                                
                                setMsgModalErro(error.message);
                                setShowModalErro(true);
                                setShowModalLoad(false);

                            }

                        }}
                        >Baixar XML</Button>{' '}
                    </div>
                </Col>
            </Row>

            <hr />

            {
                //listar NFC-e
                totalRegistros > 0
                &&
                <Table>
                    <thead>
                        <tr>
                            <th>Numero</th>
                            <th>Chave</th>
                            <th>Status</th>
                            <th>Data emissão</th>
                            <th>Total nota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notasNfce.map((item) => {
                                return(
                                    <tr key={item.UuidNfce}>
                                        <td>{item.NumeroNFCe}</td>
                                        <td>{item.Chave}</td>
                                        <td>{item.StatusFinal}</td>
                                        <td>{( (new Date(item.DataEmissao).getDate()) <= 9 ? ("0" + new Date(item.DataEmissao).getDate().toString()) : (new Date(item.DataEmissao).getDate().toString()) ).toString() + "/" + ( (new Date(item.DataEmissao).getMonth() + 1) <= 9 ? ("0" + (new Date(item.DataEmissao).getMonth() + 1).toString()) : new Date(item.DataEmissao).getMonth() + 1).toString()  + "/" + new Date(item.DataEmissao).getFullYear().toString()}</td>
                                        <td>{item.valorTotalNota != null ? item.valorTotalNota.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            }

            {
            totalRegistros > 0 
            &&<div>
                <Row>

                    <hr />

                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                //console.log("Total Paginas: ", totalPaginas);
                                //console.log("Pagina: ", pagina);

                                if(pagina > 1){
                                    setPagina(pagina - 1);
                                }

                            }}
                            ><BsArrowLeftSquare size={30} /></Button>{' '}
                        </div>

                    </Col>
                    
                    <Col>
                        
                        <Form.Control 
                            style={{textAlign: "center", alignItems: "center"}}
                            type="text"
                            value={"Pagina " + pagina + " de " + totalPaginas }
                            onChange={(e) => {

                                e.target.value = "Pagina " + pagina + " de " + totalPaginas;

                            }}
                        />

                    </Col>
                    
                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                //console.log("Total Paginas: ", totalPaginas);
                                //console.log("Pagina: ", pagina);

                                if(pagina < totalPaginas){
                                    setPagina(pagina + 1);
                                }

                            }}
                            ><BsArrowRightSquare size={30} /></Button>{' '}
                        </div>
                    
                    </Col>

                </Row>
            </div>
            }

        </Container>

    );

}
