export default {

    //URL Homologação
    //URL_BASE: "http://localhost:5031/",
    //URL_BASE: "https://contador.ht01teste.com.br/",
    
    //URL Produção
    URL_BASE: "https://contador.hostmundo.net/",

    //caminhos api
    PATH_LOGIN: "login",
    PATH_VALIDAEMAIL: "ValidarEmail",
    PATH_CADASTRAEMIALSENHA: "cadastrarSenhaEmail",
    PATH_LISTAR_EMPRESAS: "listaEmpresas",
    PATH_ENVIA_CODIGOEMAIL: "EnviarCodigoEmail",
    PATH_VALIDACODIGO: "ValidarCodigo",
    PATH_RECUPERAR_SENHA: "EsqueceuSenha",
    PATH_CADASTRAR_NOVA_SENHA: "CadastrarNovaSenha",
    PATH_ALTERAR_NOME: "AlterarNome",

    //NFCE
    PATH_CONTAR_NFCE: "ContarNfce",
    PATH_LISTANFCe: "ListaNfce",
    PATH_BAIXARXMLNFCe: "BaixarXmlNFCe",

    //NFE
    PATH_CONTAR_NFE: "ContarNFE",
    PATH_LISTANFe: "ListarNFE",
    PATH_BAIXARXMLNFe: "BaixarXmlNFE",
    PATH_CANCELARNFe: "AcaoCancelarNfe",
    PATH_CONSULTASITUACAONFe: "AcaoConsultaStatusNFE",

    //CFE
    PATH_CONTAR_CFE: "ContarCFE",
    PATH_LISTACFE: "ListaCFE",
    PATH_BAIXARXMLCFE: "BaixarXmlCFE",

    //NFSE
    PATH_CONTAR_NFSE: "ContarNFSE",
    PATH_LISTANFSE: "ListaNFSe",
    PATH_BAIXARXMLNFSE: "BaixarXmlNFSE",

    //Global propriedades
    USER_WS: "teste1",
    PASS_WS: "teste1"

};