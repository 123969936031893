import { Container, Row, Col, FloatingLabel, Form, Button, Table } from "react-bootstrap";
import NavbarOp from "../components/Navbar";
import React, { useEffect, useState } from "react";
import global from "../global";
import axios from "axios";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import * as Sentry from "@sentry/react";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import ModalSucesso from "../components/Modal/ModalSucesso";

import { BsArrowLeftSquare } from "react-icons/bs";
import { BsArrowRightSquare } from "react-icons/bs";

export default function ListaCFe(){

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [user, setUser] = React.useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = React.useState(null);
    const [notasCfe, setNotasCfe] = React.useState([]);
    const [notasCfeTodas, setNotasCfeTodas] = useState([]);
    const [prontoParaImprimir, setProntoParaImprimir] = useState(false);

    //console.log(user);

    const [numCfe] = React.useState(0);
    const [dateDe, setdateDe] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAte, setdataAte] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [pagina, setPagina] = React.useState(1);
    const [totalPaginas, setTotalPaginas] = React.useState(0);
    const [totalRegistros, setTotalRegistros] = React.useState(0);
    //padrão igual do erp
    const [totalPorPagina] = React.useState(15);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");

    //Modal Sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgModalSucesso] = React.useState("");

    //functions
    const countCFE = React.useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_CONTAR_CFE, {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                idFilial: empresaSelecionada.IdEmpresa ,
                numeroNFCe: numCfe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte
    
            })
            .then(function (response) {
    
                //console.log(response);
    
                let data = response.data;
    
                if(data.code == 0){
    
                    //
                    //console.log("count", data.content);
                    setTotalRegistros(data.content);
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
    
                }
                
            })
            .catch(function (error) {

                Sentry.captureException(error);

                setMsgModalErro(data.msg);
                setShowModalErro(true);
    
                //console.log(error);
    
            })
            .finally(function () {
                
                setShowModalLoad(false);
    
            });

        }

    }, [dataAte, dateDe, empresaSelecionada, numCfe]);

    const carregarCFE = React.useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_LISTACFE, {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                idFilial: empresaSelecionada.IdEmpresa ,
                numeroNFCe: numCfe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                numeroPagina: pagina - 1,
                numeroPorPagina: totalPorPagina

            })
            .then(function (response) {

                let data = response.data;

                if(data.code == 0){

                    //console.log("DATA LISTA NFCe" , data)

                    const dados = JSON.parse(data.content);

                    //
                    //console.log(dados);
                    setNotasCfe(dados == null ? [] : dados);
                    countCFE();

                }else{

                    setMsgModalErro(data.msg);
                    setShowModalErro(true);

                }
                
            })
            .catch(function (error) {
                
                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);

                Sentry.captureException(error);

                //console.log(error);

            })
            .finally(function () {
                setShowModalLoad(false);
            });

        }

        

    }, [dataAte, dateDe, empresaSelecionada, numCfe, pagina, totalPorPagina]);

    React.useEffect(() => {

        //console.log("PAGINA", pagina);
        carregarCFE();

    }, [pagina]);

    React.useEffect(() => {

        setTotalPaginas( Math.ceil(totalRegistros / totalPorPagina) );

    }, [totalRegistros, totalPorPagina]);

    useEffect(() => {

        if(prontoParaImprimir){
            reactToPrintFn();
            setProntoParaImprimir(false);
        }

    }, [prontoParaImprimir])

    //ref
    var componentRef = React.useRef(null);
    const reactToPrintFn = useReactToPrint({contentRef: componentRef});

    return(

        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgModalErro} />
            <ModalLoad showModal={showModalLoad} setShowModal={setShowModalLoad} msg={msgModalSucesso} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgModalSucesso} />

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            {/* Div relatorio para impressao */}
            <Container 
                className='hidden' 
                style={{
                    display: 'none'
                }}
            >
                <div id="containerPdf" ref={componentRef} style={{padding: 20}}>
                    <h3 style={{textAlign: "center"}}>Relatório de CFe</h3>
                    <hr />
                    <Table>
                        <thead>
                            <tr>
                                <th>Numero</th>
                                <th>Chave</th>
                                <th>Status</th>
                                <th>Data emissão</th>
                                <th>Total nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notasCfeTodas.map((item) => {
                                    let statusDestaNota = "";
                                    if(item.Status == "06000"){
                                        statusDestaNota = "ATIVO";
                                    }else if(item.Status == "07000"){
                                        statusDestaNota = "CANCELADO";
                                    }else{
                                        statusDestaNota = "Erro";
                                    }
                                    return(
                                        <tr key={item.IdCupom}>
                                            <td>{item.IdCupom}</td>
                                            <td>{item.Chave}</td>
                                            <td>{statusDestaNota}</td>
                                            <td>{item.DataCFeFormatada}</td>
                                            <td>{item.ValorTotal != null ? parseFloat(item.ValorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Container>

            <Row style={{marginTop: "1%", textAlign: "center"}}>
                <h1>CFE</h1>
            </Row>

            <hr/>

            <Container style={{marginTop: "1%"}}>

                <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="De: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dateDe} 
                                max={dataAte}
                                onChange={(e) => {

                                    setdateDe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Até: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dataAte} 
                                max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                                onChange={(e) => {

                                    setdataAte(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                

            </Container>

            <Row>
                <Col>
                    {totalRegistros > 0 ? <h6>Total: {totalRegistros}</h6> : null}
                </Col>
            </Row>

            <hr />

            <Row 
            style={{marginBottom: "1%"}}
            >
                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            carregarCFE();

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>

                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {
                            if(empresaSelecionada != null){

                                setShowModalLoad(true);
                            
                                axios.post(global.URL_BASE + global.PATH_LISTACFE, {
                                    
                                    idOrgranizacao: empresaSelecionada.IdOrganizacao ,
                                    idFilial: empresaSelecionada.IdEmpresa ,
                                    numeroNFCe: numCfe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte,
                                    numeroPagina: -1,
                                    numeroPorPagina: 0
                    
                                })
                                .then(function (response) {
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        //console.log("DATA LISTA NFCe" , data)
                    
                                        const dados = JSON.parse(data.content);
                    
                                        //
                                        //console.log(dados);
                                        setNotasCfeTodas(dados == null ? [] : dados);
                                        setProntoParaImprimir(true);
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                                    
                                })
                                .catch(function (error) {
                                    
                                    setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                                    setShowModalErro(true);
                    
                                    Sentry.captureException(error);
                    
                                    //console.log(error);
                    
                                })
                                .finally(function () {
                                    setShowModalLoad(false);
                                });
                    
                            }
                        }}
                        >
                            Imprimir relatorio
                        </Button>
                    </div>
                </Col>

                <Col
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            try {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_BAIXARXMLCFE, {
                    
                                    idOrganizacao: empresaSelecionada.IdOrganizacao ,
                                    idFilial: empresaSelecionada.IdEmpresa ,
                                    numeroNFCe: numCfe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte
                    
                                })
                                .then(function (response) {
                    
                                    //console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        //console.log("DATA LISTA NFCe" , data)
                    
                                        // Decodificar Base64
                                        const base64Content = data.content;
                                        const binaryString = atob(base64Content);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }

                                        // Criar Blob a partir dos dados binários
                                        const blob = new Blob([bytes], { type: 'application/zip' });

                                        // Criar URL para o Blob
                                        const url = window.URL.createObjectURL(blob);

                                        // Criar elemento de link e acionar o download
                                        const nomeArquivo = 'CFE_' + empresaSelecionada.NomeFantasia
                                        .normalize('NFD') // Decompor caracteres com acentos
                                        .replace(/[\u0300-\u036f]/g, '') // Remover os diacríticos (acentos)
                                        .replace(/\s+/g, '_') + "_" +
                                        dateDe.replaceAll("-", "_") + 
                                        (   dataAte != null 
                                            ? 
                                                "_ATE_" +
                                                dataAte.replaceAll("-", "_")
                                            :
                                            new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()
                                        ) +
                                        '.zip'
                                        ; // Substituir espaços por underscores
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', nomeArquivo); // Nome do arquivo a ser baixado
                                        document.body.appendChild(link);
                                        link.click();

                                        // Remover o link do DOM
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                        
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                    
                                    setShowModalLoad(false);
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu um erro ao baixar as CFE. Tente novamente: " + error);
                                    setShowModalErro(true);     
                                    
                                    setShowModalLoad(false);
                    
                                    //console.log(error);
                    
                                });
                                
                            } catch (error) {
                                
                                setMsgModalErro(error.message);
                                setShowModalErro(true);
                                setShowModalLoad(false);

                                Sentry.captureException(error);

                            }

                        }}
                        >Baixar XML</Button>{' '}
                    </div>
                
                </Col>

            </Row>

            <hr />

            {

                //listar CFE
                totalRegistros > 0
                &&
                <Table>
                    <thead>
                        <tr>
                            <th>Numero</th>
                            <th>Chave</th>
                            <th>Status</th>
                            <th>Data emissão</th>
                            <th>Total nota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notasCfe.map((item) => {
                                let statusDestaNota = "";
                                if(item.Status == "06000"){
                                    statusDestaNota = "ATIVO";
                                }else if(item.Status == "07000"){
                                    statusDestaNota = "CANCELADO";
                                }else{
                                    statusDestaNota = "Erro";
                                }
                                return(
                                    <tr key={item.IdCupom}>
                                        <td>{item.IdCupom}</td>
                                        <td>{item.Chave}</td>
                                        <td>{statusDestaNota}</td>
                                        <td>{item.DataCFeFormatada}</td>
                                        <td>{item.ValorTotal != null ? parseFloat(item.ValorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

            }

            {
                totalRegistros > 0 
                &&<div>

                    <hr />

                    <Row>

                        <Col>

                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    //console.log("Total Paginas: ", totalPaginas);
                                    //console.log("Pagina: ", pagina);

                                    if(pagina > 1){
                                        setPagina(pagina - 1);
                                    }

                                }}
                                ><BsArrowLeftSquare size={30} /></Button>{' '}
                            </div>

                        </Col>
                        
                        <Col>
                            
                            <Form.Control 
                                style={{textAlign: "center", alignItems: "center"}}
                                type="text"
                                value={"Pagina " + pagina + " de " + totalPaginas }
                                onChange={(e) => {

                                    e.target.value = "Pagina " + pagina + " de " + totalPaginas;

                                }}
                            />

                        </Col>
                        
                        <Col>

                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    //console.log("Total Paginas: ", totalPaginas);
                                    //console.log("Pagina: ", pagina);

                                    if(pagina < totalPaginas){
                                        setPagina(pagina + 1);
                                    }

                                }}
                                ><BsArrowRightSquare size={30} /></Button>
                            </div>
                        
                        </Col>

                    </Row>
                </div>
            }

        </Container>

    );


}
